<template>
  <hint title="copied" placement="left" mode="manual" :show="showCopied">
    <hint title="copy" placement="left" :show="showCopy">
      <i class="feather icon-clipboard iconButton mr-2" @click="copyToClipboard(text)"></i>
    </hint>
  </hint>
</template>

<script>

import { defineAsyncComponent, ref } from 'vue'
import copy from 'copy-to-clipboard'

export default {
  name: 'CopyIcon',
  components: {
    hint: defineAsyncComponent(() => import('@/application/components/elements/Hint.vue')),
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup() {
    const showCopied = ref(false)
    const showCopy = ref(true)

    return {
      copyToClipboard: (value) => {
        copy(value)
        showCopy.value = false
        showCopied.value = true
        setTimeout(() => {
          showCopy.value = true
          showCopied.value = false
        }, 1500)
      },
      showCopied,
      showCopy,
    }
  },
}
</script>
